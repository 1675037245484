<template>
	<div v-title :data-title="'三特科技-'+channel.name">
		<FHeader></FHeader>
		<article v-if="id==1">
			<FChannelImage :data="channel"></FChannelImage>
			<FTab :data="id"></FTab>
		</article>
		<article v-if="id==2">
			<FChannelImage :data="channel"></FChannelImage>
			<FTab1 :data="id"></FTab1>
		</article>
		<Footer></Footer>
	</div>
</template>

<script>
	import FHeader from "../../components/f/FHeader.vue"
	import FChannelImage from "../../components/f/FChannelImage.vue"
	import FTab from "../../components/f/FTab.vue"
	import FTab1 from "../../components/f/FTab1.vue"
	import Footer from "../../components/f/Footer.vue"
	import {getChannel} from "../../api/front";
	export default {
		name: "Index",
		components: {
			FHeader,
			FChannelImage,
			FTab,
			FTab1,
			Footer,
		},
		data() {
			return {
				id:0,
				channel: {}
			}
		},
		watch: {
			$route(route){
				this.$router.go(0); 
			}
		},
		mounted() {
			this.id=this.$route.params.id
			this.queryChannel(this.id)
		},
		beforeRouteUpdate(to, from, next) {
			this.queryChannel(to.params.id)
			next()
		},
		methods: {
			queryChannel(id) {
				getChannel(id).then(data => {
					this.channel = data.data;
					// console.log("父级",this.channel)
				})
			}
		}
	}
</script>

<style scoped>

</style>
