<template>
	<div class="tab">
		<el-tabs v-model="activeName" stretch @tab-click="handleTabsChange">
			<el-tab-pane v-for="(FItem,index) in tabList" :key="index" :label="FItem.name" :name="FItem.id">
				<div v-if="index==0">
					<div class="scenicContent">
						<div class="item" v-for="(item,index) in scenicData" :key="index" @click="goScenicDetail(FItem,item)">
							<div class="image">
								<img :src="item.image">
							</div>
							<div class="text">
								<span>{{item.title}}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="index==1">
					<div class="resultContent">
						<div class="item" v-for="(item,index) in softwareData" :key="index" @click="goSoftwareDetail(FItem,item)">
							<div class="image">
								<img :src="item.soft_image">
							</div>
							<div class="text">
								<span>{{item.title}}</span>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import {getTab} from "@/api/front"
	import {queryScenicList,querySoftwareList} from "@/api/front";
	export default {
		name: "tab",
		props: ['data'],
		data() {
			return {
				id: 0,
				activeName: '',
				tabList: [],
				total: 0,
				pageNo: 1,
				scenicData:[],
				softwareData:[],
			}
		},
		watch: {
			data: {
				immediate: true, // 这句重要
				handler(val) {
					// console.log("栏目id", val)
					this.id = val
				}
			}
		},
		mounted() {
			this.queryTab()
		},
		methods: {
			queryTab() {
				getTab(this.id).then(data => {
					this.tabList = data.data
					for(let i=0;i<this.tabList.length;i++){
						this.tabList[i].id=this.tabList[i].id.toString()
					}
					this.activeName = this.tabList[0].id
					this.queryArticle()
				})
			},
			handleTabsChange(tab) {
				this.queryArticle()
			},
			queryArticle(){
				// console.log(this.activeName)
				let param = {
					title: '',
					channelId: parseInt(this.activeName),
					page: this.pageNo,
					pageSize: 6,
				}
				if(param.channelId==5){
					queryScenicList(param).then(data => {
						this.scenicData = data.data.list
						// console.log("列表",this.scenicData)
					}).catch(error => {
						this.$message.error(error)
					})
				}else if(param.channelId==6){
					querySoftwareList(param).then(data => {
						this.softwareData = data.data.list
						// console.log("列表",this.softwareData)
					}).catch(error => {
						this.$message.error(error)
					})
				}
				
			},
			goScenicDetail(channelItem,scenicItem){
				let routerUrl=this.$router.resolve({
					path: "/scenic_detail",
					query: {
						channelId:channelItem.parent_id,
						id:scenicItem.id,
					},
				})
				window.open(routerUrl.href, '_blank');
			},
			goSoftwareDetail(channelItem,softwareItem){
				let routerUrl=this.$router.resolve({
					path: "/software_detail",
					query: {
						channelId:channelItem.parent_id,
						id:softwareItem.id,
					},
				})
				window.open(routerUrl.href, '_blank');
			},
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.tab {
		padding: 50px 15%;
	}

	::v-deep .el-tabs__nav-scroll {
		width: 40% !important;
		margin: 0 auto !important;
	}
	::v-deep .el-tabs__item{
		font-size: 24px !important;
	}
	.scenicContent {
		margin: 50px 0;
		width: calc(100% + 80px);
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		.item {
			width: 380px;
			height: 300px;
			margin-right: 88px;
			margin-bottom: 80px;
			border: 1px solid #BFBFBF;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			background-color: #797979;
			.image {
				img {
					width: 380px;
					height: 245px;
				}
			}
	
			.text {
				span {
					display: inline-block;
					height: 50px;
					width: 380px;
					text-align: center;
					font-size: 22px;
					font-family: Adobe Heiti Std R;
					color: #FFFFFF;
					line-height: 50px;
				}
			}
		}
		.item:hover{
			background-color: #1A86FF;
		}
	}
	.resultContent {
		margin: 50px 0;
		width: calc(100% + 60px);
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		.item {
			width: 400px;
			height: 520px;
			margin-right: 60px;
			margin-bottom: 80px;
			border: 1px solid #BFBFBF;
			img {
				width: 400px;
				height: 520px;
			}
			.text{
				top: -300px;
				position: relative;
				span {
					display: inline-block;
					height: 50px;
					width: 400px;
					text-align: center;
					font-size: 32px;
					font-family: Adobe Heiti Std R;
					color: transparent;
					line-height: 50px;
				}
			}
		}
		.item:hover{
			img {
				filter:brightness(60%);
			}
			.text {
				top: -300px;
				position: relative;
				span {
					display: inline-block;
					height: 50px;
					width: 400px;
					text-align: center;
					font-size: 32px;
					font-family: Adobe Heiti Std R;
					color: #FFFFFF;
					line-height: 50px;
				}
			}
		}
	}
</style>
