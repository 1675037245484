<template>
	<div class="tab">
		<el-tabs v-model="activeName" stretch @tab-click="handleTabsChange">
			<el-tab-pane v-for="(FItem,index) in tabList" :key="index" :label="FItem.name" :name="FItem.id">
				<div v-if="index==0">
					<div class="newsContent">
						<div class="block">
							<div class="item" v-for="(item,index) in tableData" :key="index" @click="goDetail(FItem,item)">
								<div class="image">
									<!-- <img src="../../assets/fImage/defaultImage.jpg"> -->
									<img :src="item.image==''||item.image==null ? defaultImageUrl : item.image">
								</div>
								<div class="title">{{item.title}}</div>
								<div class="description">{{item.description}}</div>
								<div class="time">{{item.publish}}</div>
							</div>
						</div>
						
						<el-pagination
							background 
							style="margin: 0px auto;"
							layout="total,prev, pager, next,jumper" 
							@current-change="handleCurrentChange"
							:current-page="pageNo" 
							:total="total">
						</el-pagination>
					</div>
				</div>
				<div v-if="index==1">
					<div class="infoContent">
						<div class="block">
							<div class="item" v-for="(item,index) in tableData" :key="index" @click="goDetail(item)">
								<div class="image">
									<!-- <img src="../../assets/fImage/defaultImage.jpg"> -->
									<img :src="item.image==''||item.image==null ? defaultImageUrl : item.image">
								</div>
								<div class="title">{{item.title}}</div>
								<div class="description">{{item.description}}</div>
								<div class="time">{{item.publish}}</div>
							</div>
						</div>
						
						<el-pagination
							background 
							style="margin: 0px auto;"
							layout="total,prev, pager, next,jumper" 
							@current-change="handleCurrentChange"
							:current-page="pageNo" 
							:total="total">
						</el-pagination>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import {getTab} from "@/api/front"
	import {queryArticleList} from "@/api/front";
	export default {
		name: "tab",
		props: ['data'],
		data() {
			return {
				id: 0,
				activeName: '',
				tabList: [],
				total: 0,
				pageNo: 1,
				tableData:[],
				defaultImageUrl:require('../../assets/fImage/defaultImage.jpg'),
			}
		},
		watch: {
			data: {
				immediate: true, // 这句重要
				handler(val) {
					// console.log("栏目id", val)
					this.id = val
				}
			}
		},
		mounted() {
			this.queryTab()
		},
		methods: {
			queryTab() {
				getTab(this.id).then(data => {
					this.tabList = data.data
					for(let i=0;i<this.tabList.length;i++){
						this.tabList[i].id=this.tabList[i].id.toString()
					}
					this.activeName = this.tabList[0].id
					this.queryArticle()
				})
			},
			handleTabsChange(tab) {
				this.queryArticle()
			},
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.queryArticle()
			},
			queryArticle(){
				// console.log(this.activeName)
				let param = {
					title: '',
					channelId: parseInt(this.activeName),
					page: this.pageNo,
					pageSize: 6,
				}
				queryArticleList(param).then(data => {
					this.tableData = data.data.list
					this.total = data.data.total
					// console.log("列表",this.tableData)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			goDetail(channelItem,item){
				let routerUrl=this.$router.resolve({
					path: "/detail",
					query: {
						channelId:channelItem.parent_id,
						id:item.id,
					},
				})
				window.open(routerUrl.href, '_blank');
			},
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.tab {
		padding: 50px 15%;
	}

	::v-deep .el-tabs__nav-scroll {
		width: 40% !important;
		margin: 0 auto !important;
	}
	::v-deep .el-tabs__item{
		font-size: 24px !important;
	}
	.newsContent,.infoContent{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		.block {
			margin-top: 50px;
			width: calc(100% + 60px);
			display: flex;
			flex-wrap: wrap;
			cursor: pointer;
			.item{
				width: 340px;
				height: 450px;
				padding: 30px;
				margin-right: 60px;
				margin-bottom: 65px;
				border-radius: 5px;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				background-color: #f9fafc;
				.image{
					img{
						width: 340px;
						height: 200px;
					}
				}
				.title{
					color: #000000;
					font-size: 24px;
					margin-top: 12px;
					height: 60px;
					overflow: hidden;
				}
				.description{
					color: #999999;
					font-size: 18px;
					margin-top: 12px;
					height: 124px;
					overflow: hidden;
				}
				.time{
					color: #999999;
					font-size: 20px;
					text-align: center;
					margin-top: 10px;
				}
			}
		}
	}
</style>
